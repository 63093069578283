import React from 'react';
import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';

import Routes from './Routes';

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
